<script>

import AdvancedTable from "@/components/elements/advanced-table.vue";
import {mapGetters} from "vuex";
import PositionHierarchyManager from "../../../../components/elements/position-hierarchy-manager.vue";

export default {
    components: {
        PositionHierarchyManager,
        AdvancedTable,
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale'
        }),

        fields: function () {
            return [
                {
                    key: "hierarchy_level",
                    label: this.$t('organizations::positions.relationships.hierarchy_level').ucFirst(),
                    formatter: (value) => value?.translation_key ? this.$t(value.translation_key).ucFirst() : value?.name,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "name",
                    label: this.$t('organizations::positions.columns.name').ucFirst(),
                    formatter: (value, index, item) => item.translation_key ? this.$t(item.translation_key).ucFirst() : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "icon",
                    label: this.$t('organizations::positions.columns.icon').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "color",
                    label: this.$t('organizations::positions.columns.color').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "is_ceo",
                    label: this.$t('organizations::positions.columns.is_ceo').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];
        },
    },

    data() {
        return {
            showModal: false,
            title: '',
            content: ''
        };
    },

    methods: {
        readDescription: function (item) {
            this.title = item?.translation_key ? this.$t(item.translation_key).ucFirst() : item?.name
            this.content = item?.description_translation_key ? this.$t(item.description_translation_key).ucFirst() : item?.description
            this.showModal = true
        },

        setCeo: function (id) {
            this.$store.dispatch('orgPosition/updateCeo', {id: id});
        },
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'organizations::positions.position', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <div class="">
        <advanced-table
            :caption="$tc('organizations::positions.position', 2).ucFirst()"
            :create-link="{ name: 'org-position-create' }"
            :current-page="1"
            :edit-link="{ name: 'org-position-edit' }"
            :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
            :empty-text="$t('base.empty_table').ucFirst()"
            :fields="fields"
            :queries="{ with: ['hierarchyLevel']}"
            :per-page="10"
            borderless
            deletable
            editable
            hover
            moduleNamespace="orgPosition"
            outlined
            permission="positions"
            primary-key="id"
            responsive="sm"
            searchable
            show-empty
            sort-by="id"
            sort-direction="asc"
            sort-icon-left
        >
            <template #icon="{value}">
                <i v-if="value" :class="value"></i>
            </template>
            <template #color="{value}">
                <span v-if="value" class="cube" :style="{background: value}"></span>
            </template>
            <template #more-action="{item}">
                <li class="list-inline-item">
                    <a
                        v-if="!item.is_ceo"
                        @click.prevent="setCeo(item.id)"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="$t('organizations::positions.set_it_as_ceo').ucFirst()"
                        class="px-2 text-muted cursor-pointer"
                    ><i class="mdi mdi-account-tie font-size-18"></i
                    ></a>
                </li>
                <li class="list-inline-item">
                    <a
                        @click.prevent="readDescription(item)"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="$t('base.open').ucFirst()"
                        class="px-2 text-info cursor-pointer"
                    ><i class="bx bx-detail font-size-18"></i
                    ></a>
                </li>
            </template>
        </advanced-table>
        <div class="card">
            <div class="card-header">
                <h5 class="card-title">{{ $tc('organizations::position_superiors.position_superior', 2).ucFirst() }}</h5>
            </div>
            <div class="card-body rounded-bottom bg-light">
                <position-hierarchy-manager></position-hierarchy-manager>
            </div>
        </div>
    </div>
</template>

<style scoped>
.cube {
    display: inline-block;
    width: 12px;
    aspect-ratio: 1 / 1;
}
</style>
