<script>
import draggable from "vuedraggable";
import PositionCard from "./position-card.vue";
import {mapGetters} from "vuex";
import PositionLine from "./position-line.vue";

export default {
    name: "position-hierarchy-manager",

    components: {PositionLine, PositionCard, draggable},

    computed: {
        ...mapGetters({
            items: 'orgPosition/all',
            hierarchyLevels: 'hierarchyLevel/all',
            hierarchicalChains: 'hierarchicalChain/all',
            pivots: 'positionSuperior/all'
        }),

        filtered: function () {
            return this.elements.filter(e => !e.is_ceo && !this.lists.some(l => l.list?.findIndex(i => i.id === e.id) > -1));
        },

        ceo: function () {
            return this.elements.find(e => e.is_ceo);
        },
    },

    data: function () {
        return {
            elements: [],
            lists: [[]],
            filteredList: [],
            showModal: false,
            title: null,
            content: null
        };
    },

    methods: {
        fetch: function () {
            const promises = [
                this.$store.dispatch('hierarchyLevel/all'),
                this.$store.dispatch('hierarchicalChain/all'),
                this.$store.dispatch('positionSuperior/all'),
                this.$store.dispatch('orgPosition/all')
            ];

            Promise.all(promises).then(() => {
                this.initLists();
            })
        },

        initLists: function () {
            let list = [[]];

            if (this.hierarchicalChains?.length) {
                const levelsProp = this.hierarchyLevels?.length
                    ? this.hierarchyLevels?.map(() => [])
                    : [];

                list = this.hierarchicalChains?.map(() => levelsProp);
            }

            if (this.pivots?.length) {
                this.pivots.forEach(pivot => {
                    const chainIndex = this.hierarchicalChains.findIndex(c => c.id === pivot.hierarchical_chain_id);
                    const levelIndex = this.hierarchyLevels.findIndex(l => l.id === pivot.hierarchy_level_id);
                    const position = this.filteredList.find(fl => fl.id === pivot.position_id);

                    if (chainIndex > -1 && position) {

                        if (levelIndex > -1) {
                            list[chainIndex][levelIndex].push(position);
                        } else {
                            list[chainIndex.push(position)]
                        }

                    }
                });
            }

            this.lists = list;
        },

        save: function () {
            const pivots = [];
            let prev = this.ceo;

            this.lists.forEach((chainList, chainIndex) => {
               // chain level
                chainList.forEach((levelList, levelIndex) => {
                    levelList.forEach(item => {
                       pivots.push({
                           hierarchical_chain_id: this.hierarchicalChains[chainIndex]?.id,
                           hierarchy_level_id: this.hierarchyLevels[levelIndex]?.id,
                           position_id: item.id,
                           superior_id: prev?.id
                       });
                       prev = item;
                   })
               })
            });

            this.$store.dispatch('positionSuperior/updateAll', pivots);
        },

        readDescription: function (position) {
            this.title = position?.translation_key ? this.$t(position.translation_key).ucFirst() : position?.name;
            this.content = position.description;
            this.showModal = true;
        },
    },

    watch: {
        items: {
            immediate: true,
            deep: true,
            handler: function () {
                this.elements = JSON.parse(JSON.stringify(this.items)) || [];
            },
        },

        filtered: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.filteredList = JSON.parse(JSON.stringify(value || [])) || [];
            },
        }
    },

    created() {
        this.fetch();
    }
}
</script>

<template>
    <div class="position-hierarchy-manager">
        <div v-if="ceo" class="ceo text-center border-bottom mb-4">
            <position-card class="d-inline-flex mb-2" :position="ceo" ceo click-description></position-card>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th></th>
                    <th v-for="(chain, index) in hierarchicalChains" :key="index" class="text-center">
                        <div class="d-inline-flex cursor-pointer flex-column" @click="readDescription(chain)">
                            <i v-if="chain?.icon" :class="chain?.icon" :style="{color: chain?.color}" class="font-size-24"></i>
                            <h6>{{ chain?.name ? $t(chain.name).ucFirst() : chain?.name }}</h6>
                        </div>
                    </th>
                    <th class="no-wrap">
                        {{ $t('organizations::position_superiors.unhierarchical_elements').ucFirst() }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(hierarchyLevel, index) in hierarchyLevels" :key="hierarchyLevel.id">
                    <td style="width: 1%;background-color: white" class="vertical-middle text-center">
                        <div class="d-inline-flex flex-column cursor-pointer" @click="readDescription(hierarchyLevel)">
                            <i v-if="hierarchyLevel?.icon" :class="hierarchyLevel.icon" class="mb-2 font-size-24" :style="{color: hierarchyLevel?.color}"></i>
                            <span class="badge w-100 no-wrap" :style="{backgroundColor: hierarchyLevel?.color}">
                                <span>{{ hierarchyLevel?.translation_key ? $t(hierarchyLevel?.translation_key).ucFirst() : hierarchyLevel?.name }}</span>
                            </span>
                        </div>
                    </td>
                    <td v-for="(chain, chainIndex) in hierarchicalChains" :key="chainIndex">
                        <draggable class="list-group min-height" item-key="id" :list="lists[chainIndex][index]" group="people">
                            <template #item="{element, index}">
                                <position-line
                                    class="list-group-item mb-1 p-1"
                                    :position="element"
                                    :key="index"
                                >
                                </position-line>
                            </template>
                        </draggable>
                    </td>
                    <td v-if="index === 0" :rowspan="hierarchyLevels.length" style="width: 1%;background-color: white">
                        <draggable
                            class="d-flex flex-column"
                            tag="div"
                            v-model="filteredList"
                            item-key="id"
                            group="people"
                        >
                            <template #item="{element, index}">
                                <position-line class="mb-1" :position="element" :key="index"></position-line>
                            </template>
                        </draggable>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <b-button variant="success" @click="save">{{ $t('base.save').ucFirst() }}</b-button>
            </div>
        </div>
        <b-modal v-model="showModal" :title="title" hide-footer>
            {{ content }}
        </b-modal>
    </div>
</template>

<style scoped>
    .min-height {
        min-height: 50px;
    }
    .vertical-middle {
        vertical-align: middle;
    }
</style>
